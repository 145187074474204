<template>
  <v-container class="Product_bar">
    <v-row>
      <v-col cols="4">
        <h3 class="System_admin_text_Product ml-2">TASKS</h3>
      </v-col>

      <v-col cols="12" sm="3">
        <p>
          <span
            ><b>DEAL: </b>
            <span
              ><b>{{ this.currentDeal }}</b></span
            ></span
          >
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="7">
        <div class="text-right">
          <b-button-group>
            <b-button @click="openOffers" class="offers-button"
              >Offers</b-button
            >
            <b-button @click="openUploads" class="uploads-button"
              >Uploads</b-button
            >
            <b-button @click="openWorksheets" class="worksheets-button"
              >WorkSheets</b-button
            >
            <b-button @click="getData()" class="showall-button">
              <template slot="button-content">
                <b-icon icon="arrow-up"></b-icon> Features
              </template>
              Show All</b-button
            >
            <b-button @click="getCompletedData()" class="purple-button"
              >Only Completed</b-button
            >
            <b-button @click="getIncompleteData()" class="blah-button"
              >Only Incomplete</b-button
            >
            <b-button @click="openEdit" class="add-button">Add</b-button>
          </b-button-group>
        </div>
      </v-col>
    </v-row>
    <!-- <v-row>
            <v-col cols="12" md="4" class="pt-0 pb-0">
                <v-text-field
                        label="Search"
                        v-model="searchText"
                        clearable
                        @click:clear="clearSearch"
                        append-icon="mdi-magnify"
                        @keyup.enter="getData"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
                <v-btn outlined rounded @click="getData">Search</v-btn>
            </v-col>
        </v-row> -->

    <v-col cols="12" class="customer-search-table">
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1 cursor-pointer"
      >
        <template v-slot:item.task_type="props">
          <v-edit-dialog
            :return-value.sync="props.item.task_type"
            @save="saveEditDialog(props.item)"
          >
            {{ props.item.task_type }}
            <template v-slot:input>
              <v-select
                class="v-input--hide-details"
                label="User"
                v-model="props.item.task_type"
                :items="list_tasktype"
              ></v-select>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.task="props">
          <v-edit-dialog
            :return-value.sync="props.item.task"
            @save="saveEditDialog(props.item)"
          >
            {{ props.item.task }}
            <template v-slot:input>
              <v-text-field
                class="v-input--hide-details"
                label="Task"
                v-model="props.item.task"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.assigned_to_id="props">
          <v-edit-dialog
            :return-value.sync="props.item.assignedto"
            @save="saveEditDialog(props.item)"
          >
            {{ props.item.assignedto }}
            <template v-slot:input>
              <v-select
                class="v-input--hide-details"
                label="Permission Level"
                v-model="props.item.assigned_to_id"
                :items="list_user"
                item-value="id"
                item-text="text"
              ></v-select>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.assigned_by_id="props">
          <v-edit-dialog
            :return-value.sync="props.item.assignedby"
            @save="saveEditDialog(props.item)"
          >
            {{ props.item.assignedby }}
            <template v-slot:input>
              <v-checkbox
                class="v-input--hide-details"
                label="Required"
                v-model="props.item.assigned_by_id"
              ></v-checkbox>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.comments="props">
          <v-edit-dialog
            :return-value.sync="props.item.comments"
            @save="saveEditDialog(props.item)"
          >
            {{ props.item.comments }}
            <template v-slot:input>
              <v-text-field
                class="v-input--hide-details"
                label="Comments"
                v-model="props.item.comments"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.due_date="props">
          <v-edit-dialog
            :return-value.sync="props.item.due_date"
            @save="saveEditDialog(props.item)"
          >
            {{ props.item.due_date }}
            <template v-slot:input>
              <v-text-field
                class="v-input--hide-details"
                label="Due Date"
                v-model="props.item.due_date"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.completed="props">
          <v-edit-dialog
            :return-value.sync="props.item.completed_yesno"
            @save="saveEditDialog(props.item)"
          >
            {{ props.item.completed_yesno }}
            <template v-slot:input>
              <v-select
                class="v-input--hide-details"
                label="Complete"
                v-model="props.item.completed"
                :items="list_yesno"
                item-text="text"
                item-value="id"
              ></v-select>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.complete_date="props">
          <v-edit-dialog
            :return-value.sync="props.item.complete_date"
            @save="saveEditDialog(props.item)"
          >
            {{ props.item.complete_date }}
            <template v-slot:input>
              <v-text-field
                class="v-input--hide-details"
                label="Complete Date"
                v-model="props.item.complete_date"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            v-if="canput === 'true'"
            color="warning"
            small
            class="mr-5"
            @click="editedItems(item)"
            >fas fa-edit</v-icon
          >
          <v-icon
            v-if="candelete === 'true'"
            color="error"
            small
            @click="deleteItem(item.id)"
            >fas fa-trash-alt</v-icon
          >
        </template>
      </v-data-table>
    </v-col>

    <v-row>
      <v-col cols="12">
        <div class="header-add-systemAdmin" style="float: right">
          <b-button class="back-button" @click="goBack">Back </b-button>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogEdit" max-width="600" persistent>
      <v-card>
        <div class="">
          <div class="popup-wrap">
            <v-btn class="close-popup" text @click="closeEdit">
              <v-img src="../assets/close-icon.png" alt="close-icon"></v-img>
            </v-btn>

            <div class="popup-wrap-inner step-one">
              <div class="popup-title">
                <h6>{{ editedItem.id ? "Update Task" : "Add Task" }}</h6>
              </div>
              <div class="customer-form">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-container>
                    <v-row class="px-5">
                      <!-- PLACE COLUMNS HERE -->
                      <p></p>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <v-select
                            style="text-align: center"
                            v-model="editedItem.task_type"
                            label="Task Type"
                            :items="list_tasktype"
                            item-value="id"
                            item-text="text"
                            :rules="[(v) => !!v || 'This field is required']"
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <v-text-field
                            style="text-align: center"
                            v-model="editedItem.task"
                            label="Task"
                            :rules="[(v) => !!v || 'This field is required']"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <v-autocomplete
                            label="Assigned To"
                            v-model="editedItem.assigned_to_id"
                            :items="list_user"
                            item-value="id"
                            item-text="text"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <v-autocomplete
                            label="Assigned By"
                            v-model="editedItem.assigned_by_id"
                            :items="list_user"
                            item-value="id"
                            item-text="text"
                          >
                          </v-autocomplete>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <v-text-field
                            style="text-align: center"
                            v-model="editedItem.comments"
                            label="Comments"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          md="4"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <template>
                            <div>
                              <b-form-datepicker
                                id="start-datepicker"
                                v-model="editedItem.due_date"
                                class="mb-2"
                                placeholder="Select Due Date"
                              ></b-form-datepicker>
                            </div>
                          </template>
                        </v-col>
                        <v-col
                          cols="12"
                          md="4"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <b-form-checkbox
                            label="Completed"
                            v-model="editedItem.completed"
                            value="true"
                            unchecked-value="false"
                          >
                            Completed
                          </b-form-checkbox>
                        </v-col>
                        <v-col
                          cols="12"
                          md="4"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <template>
                            <div>
                              <b-form-datepicker
                                id="end-datepicker"
                                v-model="editedItem.complete_date"
                                class="mb-2"
                                placeholder="Completed Date"
                              ></b-form-datepicker>
                            </div>
                          </template>
                        </v-col>
                      </v-row>

                      <v-col cols="12">
                        <div class="text-right">
                          <v-btn color="primary" dark @click="saveFormData">
                            Save
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">
          <span>Confirm Delete</span>
        </v-card-title>
        <v-card-text class="subtitle-1 pa-5">
          <span>Are you sure you want to delete this item?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDelete">Cancel</v-btn>
          <v-btn text @click="deleteItemConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import CommonServices from "../service/CommonServices";
import XLSX from "xlsx";

export default {
  created() {
    this.checkPermissions();   

    //this.checkPermissions();
    this.logActivity("Opened", this.editedItem.deal_id);

    CommonServices.listWithParams(this.componentURL, this.items)
      .then((response) => {
        console.log("Table Items: ", response.results);
        this.items = response.results;
        //localStorage.setItem('Deal', this.items[0].deal)
        console.log("currentDeal: ", this.currentDeal);
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getList(`vwDealUsers/?id=${this.$route.params.id}`)
      .then((res) => {
        console.log("Deal Users", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_user.push({ id: code.user_id, text: code.user })
        );
        console.log("List Users", this.list_user);
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getList("TaskType")
      .then((res) => {
        console.log("Task Types: ", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_tasktype.push({ id: code.id, text: code.task_type })
        );
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getList(this.componentURL_deal_folders)
      .then((res) => {
        console.log("Deal Folders: ", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_deal_folder.push({ id: code.id, text: code.folder })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  },

  data() {
    return {
      componentURL: `Tasks/?deal_id=${this.$route.params.id}`,
      componentURL_base: `Tasks`,
      componentURL_completed: `Tasks/?deal_id=${this.$route.params.id}&completed=True`,
      componentURL_incomplete: `Tasks/?deal_id=${this.$route.params.id}&completed=False`,

      canpost: localStorage.getItem("canpost"),
      candelete: localStorage.getItem("candelete"),
      canput: localStorage.getItem("canput"),
      isAdmin: localStorage.getItem("Admin"),
      isBroker: localStorage.getItem('role')==2,
      isSeller: localStorage.getItem('role')==3,  
      isBuyer: localStorage.getItem('role')==4,
      isDIY: localStorage.getItem('role')==7,
      role: localStorage.getItem('role'),
      valid: false,
      dialog: false,
      dialogDelete: false,
      dialogEdit: false,
      deletedId: null,
      snackbar: false,
      snackColor: "",
      snackText: "",
      error: false,
      loading: false,
      editedIndex: -1,
      searchText: "",
      currentDeal: localStorage.getItem("Deal"),

      headers: [
        { text: "ACTIONS", value: "actions", sortable: false },
        { text: "TASK TYPE", value: "task_type", sortable: true },
        { text: "TASK NAME", value: "task", sortable: true },
        { text: "ASSIGNED TO", value: "assigned_to_id", sortable: true },
        { text: "ASSIGNED BY", value: "assigned_by_id", sortable: true },
        { text: "COMMENTS", value: "comments", sortable: true },
        { text: "DUE DATE", value: "due_date", sortable: true },
        { text: "COMPLETE", value: "completed", sortable: true },
        { text: "COMPLETE DATE", value: "complete_date", sortable: true },
      ],

      items: [],

      list_tasktype: [],
      list_user: [],
      list_yesno: [
        { id: true, text: "Yes" },
        { id: false, text: "No" },
      ],

      userActivityItem: {
        user_id: localStorage.getItem("userid"),
        logdate: this.$moment(String(Date())).format("YYYY-MM-DDThh:mm"),
        page: "Tasks",
        action: "",
        deal_id: "",
        json: "",
      },

      editedItem: {
        id: "",
        deal_id: this.$route.params.id,
        task_type: "",
        task: "",
        assigned_to_id: "",
        assigned_by_id: "",
        due_date: null,
        complete_date: null,
        comments: "",
        completed: false,
        completed_yesno: false,
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    checkPermissions() {
      if (this.isAdmin == "false") this.$router.push("/");
    },

    logActivity(activity, dealid, json) {
      this.userActivityItem.action = activity;
      this.userActivityItem.logdate = this.$moment(String(Date())).format(
        "YYYY-MM-DDThh:mm"
      );
      this.userActivityItem.deal_id = dealid;
      this.userActivityItem.json = json;
      CommonServices.postData("UserActivityLog", this.userActivityItem)
        .then((response) => {
          console.log("UserActivityLog Response: ", response.id);
        })
        .catch((error) => {
          console.log("Post Activity Error: ", error);
        });
    },

    openEdit() {
      this.editedItem = {};
      console.log("ID: ", this.editedItem.id);
      this.editedItem.assigned_by_id = this.$route.params.id;
      this.dialogEdit = true;
    },

    openOffers() {
      localStorage.setItem("DealID", this.$route.params.id);
      this.$router.push(`/DealOffers/${this.$route.params.id}`);
    },

    openBlindAd() {
      localStorage.setItem("DealID", this.$route.params.id);
      this.$router.push(`/BlindAdWorksheet/${this.$route.params.id}`);
    },

    openWorksheets() {
      localStorage.setItem("DealID", this.$route.params.id);
      this.$router.push(`/WorkSheets/`);
    },

    openUploads() {
      //First write any missing Deal Folders to the upload table
      localStorage.setItem("DealID", this.$route.params.id);
      CommonServices.putData("Functions", { id: 3 })
        .then((response) => {
          console.log("Put Function Response: ", response.id);
          setTimeout(() => {
            this.$router.push(`/Uploads/${this.$route.params.id}`);
          }, 500);
        })
        .catch((error) => {
          console.log("Put Function Error: ", error);
        });
    },

    getCompletedData() {
      console.log("Completed URL: ", this.componentURL_completed);
      CommonServices.listWithParams(this.componentURL_completed, this.items)
        .then((response) => {
          this.items = response.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getIncompleteData() {
      CommonServices.listWithParams(this.componentURL_incomplete, this.items)
        .then((response) => {
          this.items = response.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    exportToExcel() {
      // On Click Excel download button
      // export json to Worksheet of Excel
      var reportWS = XLSX.utils.json_to_sheet(this.items);
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheets to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, reportWS, "Tasks"); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, "Tasks.xlsx");
    },

    saveEditDialog(item) {
      CommonServices.updateData(this.componentURL, item).then((response) => {
        const index = this.items.findIndex((item) => item.id === response.id);
        this.$set(this.items, index, response);
      });
      this.snackbar = true;
      this.snackColor = "success";
      this.snackText = "Record successfully updated";
    },

    saveFormData() {
      if (this.$refs.form.validate()) {
        if (this.editedItem.due_date) {
          this.editedItem.due_date = this.$moment(
            String(this.editedItem.due_date)
          ).format("MM/DD/YYYY");
        }
        if (this.editedItem.complete_date) {
          this.editedItem.complete_date = this.$moment(
            String(this.editedItem.complete_date)
          ).format("MM/DD/YYYY");
          this.editedItem.completed = true;
        } else {
          this.editedItem.completed = false;
        }
        if (this.editedItem.id) {
          CommonServices.updateData(this.componentURL_base, this.editedItem)
            .then((response) => {
              const index = this.items.findIndex(
                (item) => item.id === response.id
              );
              this.$set(this.items, index, response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully updated";
              this.logActivity(
                "Updated Form Data",
                this.editedItem.deal_id,
                JSON.stringify(this.editedItem)
              );
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.response.data).forEach((key) => {
                  this.snackText = error.response.data[key][0];
                });
              } catch (e) {
                this.snackText = "Something went wrong";
              }
            });
        } else {
          CommonServices.addRecord(this.componentURL, this.editedItem)
            .then((response) => {
              this.items.push(response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully added";
              this.logActivity(
                "Added Task",
                this.editedItem.deal_id,
                JSON.stringify(this.editedItem)
              );
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.data).forEach((key) => {
                  this.snackText = error.data[key][0];
                });
              } catch (e) {
                this.snackText = "Something went wrong";
              }
            });
        }
        this.close();
        this.closeEdit();
      }
    },

    getData() {
      this.loading = true;
      let params = this.params ? this.params : {};
      if (this.search !== "") params["search"] = this.searchText;
      CommonServices.listWithParams(this.componentURL, { params: params })
        .then((response) => {
          this.items = response.results;
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Error while filtering " + this.searchText;
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    deleteItem(id) {
      this.deletedId = id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      CommonServices.deleteRecord(this.componentURL_base, this.deletedId)
        .then(() => {
          let index = this.items.findIndex(
            (item) => item.id === this.deletedId
          );
          this.items.splice(index, 1);
          this.snackbar = true;
          this.snackColor = "success";
          this.snackText = "Record successfully deleted";
          this.logActivity(
            "Deleted Record",
            this.editedItem.deal_id,
            JSON.stringify(this.editedItem)
          );
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = error;
          console.log(error);
        });
      this.closeDelete();
    },

    deleteItemSilently() {
      CommonServices.deleteRecord(this.componentURL_base, this.editedItem.id)
        .then(() => {
          let index = this.items.findIndex(
            (item) => item.id === this.deletedId
          );
          this.items.splice(index, 1);
          this.getData();
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = error;
          console.log(error);
        });
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
    },

    editedItems(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = true;
    },

    clearSearch() {
      this.searchText = "";
      this.getData();
    },

    close() {
      this.$refs.form.reset();
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem);
        this.editedIndex = -1;
      });
    },

    closeEdit() {
      this.$refs.form.reset();
      this.dialogEdit = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem);
        this.editedIndex = -1;
      });
    },
    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
button {
  color: white;
  border-radius: 2px;
  cursor: pointer;
  font-size: 12px;
}

.offers-button {
  background-color: rgba(145, 9, 31, 255);
  margin-left: 0px;
  margin-right: 8px;
  height: 36px;
  width: 125px;
}
.tasks-button {
  background-color: rgba(160, 152, 131, 255);
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.uploads-button {
  background-color: rgba(81, 109, 131, 255);
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.blue-button {
  background-color: rgb(13, 13, 68);
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}
.close-popup .v-image__image {
  width: 20px !important;
  object-fit: contain !important;
  background-size: contain !important;
}
.popup-wrap {
  padding: 40px 0;
}

.popup-title {
  padding: 0 50px;
  text-align: center;
}

.popup-title h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 37px;
  color: #000000;
}

.close-popup.v-btn {
  position: absolute;
  right: 30px;
  top: 30px;
  padding: 0 !important;
}

.close-popup.v-btn > span + span {
  display: none !important;
}

.close-popup.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 !important;
  height: auto;
  width: auto;
  min-width: auto;
}

.close-popup.v-btn:before {
  content: none;
}

.add-button {
  background-color: rgba(194, 88, 48, 255);
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.back-button {
  background-color: rgba(0, 0, 0, 255);
  border: none;
  border-radius: 8px;
  border-radius: 1px;
  height: 36px;
  width: 125px;
}

.worksheets-button {
  background-color: rgba(0, 0, 0, 255);
  border: none;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.showall-button {
  background-color: rgba(227, 223, 212, 255);
  border: none;
  color: black;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.completed-button {
  background-color: rgba(81, 109, 131, 255);
  border: none;
  margin-left: 0px;
  margin-right: 0px;
  height: 36px;
  width: 125px;
}

.incomplete-button {
  background-color: rgba(81, 109, 131, 255);
  border: none;
  margin-left: 0px;
  margin-right: 0px;
  height: 36px;
  width: 125px;
}
.purple-button {
  background-color: rgba(124, 96, 121, 255);
  border: none;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.orange-button {
  background-color: rgba(194, 86, 47, 255);
  border: none;
  margin-left: 8px;
  margin-right: 8px;
  height: 36px;
  width: 125px;
}

.biege-button {
  background-color: rgba(227, 223, 212, 255);
  border: none;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.blah-button {
  background-color: rgba(160, 152, 131, 255);
  border: none;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

input#file-input {
  display: none;
}

input#file-input + label {
  background-color: #0157a0;
  padding: 4px;
  color: #fff;
  border: 2px solid #85bdee;
  border-radius: 9px;
}

input#file-input + label:hover {
  background-color: #20e0a5;
  border-color: #fff;
  cursor: pointer;
}
.err-message {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: red;
  line-height: 0pt;
}

.small-lbl {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 0pt;
}

.flex-end {
  justify-content: flex-end;
}

.header-add-Product {
  text-align: right;
  margin-left: 0%;
}

.header-add-Product button {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #20e0a5;
  box-shadow: none !important;
  color: #fff !important;
  padding: 8px 12px !important;
  border-radius: 60px;
}

.header-add-Product:focus,
.header-add-Product button:focus {
  outline: none !important;
}

.header-add-systemAdmin a {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #0157a0;
  box-shadow: 0px 4px 10px rgba(1, 87, 160, 0.25);
  color: #fff !important;
  padding: 12px 35px;
  border-radius: 60px;
}

@media only screen and (max-width: 768px) {
  .header-add-Product {
    margin-left: 0 !important;
  }
}

.flex-end {
  justify-content: flex-end;
}

.header-add-Product {
  text-align: right;
  margin-left: 0%;
}

.header-add-Product button {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #20e0a5;
  box-shadow: none !important;
  color: #fff !important;
  padding: 8px 12px !important;
  border-radius: 60px;
}

.header-add-Product:focus,
.header-add-Product button:focus {
  outline: none !important;
}

.header-add-systemAdmin a {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #0157a0;
  box-shadow: 0px 4px 10px rgba(1, 87, 160, 0.25);
  color: #fff !important;
  padding: 12px 35px;
  border-radius: 60px;
}

@media only screen and (max-width: 768px) {
  .header-add-Product {
    margin-left: 0 !important;
  }
}
</style>
